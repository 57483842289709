export default {
  title: 'Rotary Cam Switches',
  subtitle: 'Our',
  text: 'Here you can find an overview of our mechanic and electronic Rotary Cam Switches and their accessories:',
  downloads: {
    entireCatalogue: 'Entire Catalogue',
    rotaryCamSwitches: 'Rotary Cam Switches',
    gearboxes: 'Gearboxes',
    couplings: 'Couplings',
    protectiveHousing: 'Protective Housings'
  }
}
