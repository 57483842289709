import Vue from 'vue'
import VueI18n from 'vue-i18n'

import * as de from './de'
import * as en from './en'

Vue.use(VueI18n)

const defaultLocale = navigator.language

export default new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages: {
    de,
    en
  }
})
