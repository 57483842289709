export default {
  title: 'Maschinenbau',
  subtitle: 'Hochwertiger',
  production: 'Fertigungsübersicht',
  machines: 'Maschinenübersicht',
  pieces: 'Teileübersicht',
  cncTurning: 'CNC-Drehen',
  cncTurningInfo: 'Umlaufdurchmesser über Querschlitten x Spitzenweite bzw. Umlaufdurchmesser über Bett',
  pCncTurningType1: 'Ø 270 x 700 bzw. Ø 350 mm',
  pCncTurningType2: 'Ø 410 x 2000 bzw. Ø 760 mm',
  pCncTurningType3: 'Ø 560 x 2000 bzw. Ø 810 mm',
  pCncTurningType4: 'Ø 738 x 2063 bzw. Ø 960 mm',
  pCncTurningType5: 'Ø 425 x 1000 mm bzw. Ø 600 mm',
  mCncTurningMachines1: 'Gildemeister NEF400, NEF720, Seiger SLZ800E',
  mCncTurningMachines2: 'Mori Seiki SL-403BMC, Monforts RNC500, VDF Böhringer VDF180CU',
  cncMilling: 'CNC-Fräsen',
  cncMillingInfo: 'Verfahrweg x-Achse, y-Achse, z-Achse',
  pCncMillingType1: '680 x 680 x 680 mm, 3-Achs-Horizontal',
  pCncMillingType2: '800 x 630 x 360 mm, 3-Achs-Vertikal',
  pCncMillingType3: '2320 x 550 x 600 mm, 3-Achs-Universal',
  pCncMillingType4: '4000 x 900 x 950 mm, 4-Achs-Universal mit Rundtisch Ø 850 mm',
  pCncMillingType5: '2000 x 2000 x 2000 mm, CNC-Bohrwerksdrehen',
  mCncMillingMachines1: 'DMG DMU80, Hitachi Seiki HS500, TOS WHN13',
  mCncMillingMachines2: 'Axa VSC1-XTS, Axa VHC3-4000 XTS 50',
  cncGrinding: 'CNC-Schleifen',
  cncGrindingInfo: 'Schleifdurchmesser x Länge; Verfahrweg x-Achse, y-Achse, z-Achse',
  pCncGrindingType1: 'Ø 349 x 1180 mm, Rundschleifen außen',
  pCncGrindingType2: 'Ø 400 x 2000 mm, Rundschleifen außen',
  pCncGrindingType3: 'Ø 340 x 180 mm, Rundschleifen innen',
  pCncGrindingType4: '1000 x 500 x 600 mm, Flachschleifen',
  pCncGrindingType5: '1000 x 330 x 550 mm, Flachschleifen',
  mCncGrindingMachines1: 'Studer S33, WMW SA 6/1 U',
  mCncGrindingMachines2: 'ELB SWBDE 10 NC-K, Ziersch Z510',
  drilling: 'Nuträumen, Nutstoßen, Bohren',
  cncMeasuring: 'CNC-Messmaschine',
  mCncMeasuringMachines: 'Mitutoyo CRYSTA APEX 9106',
  cadCamProgramming: 'CAD / CAM Programmiersystem',
  mCadCamProgrammingMachines: 'Topsolid'
}
