import Vue from 'vue'
import App from './App.vue'
import router from './router'

import axios from 'axios'
import VueAxios from 'vue-axios'

import i18n from '@/translations'

// Components
import SolidButton from '@/components/global/SolidButton.vue'
import Divider from '@/components/global/Divider.vue'
import SectionHeader from '@/components/global/SectionHeader.vue'

Vue.component('solid-button', SolidButton)
Vue.component('divider', Divider)
Vue.component('section-header', SectionHeader)

Vue.use(VueAxios, axios)

Vue.config.productionTip = true

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
