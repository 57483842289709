export default {
  title: 'Schaltwerkstechnik',
  subtitle: 'Unsere',
  text: 'Nachfolgend finden Sie eine Übersicht über unsere mechanischen und elektronischen Nockenschaltwerke sowie deren Zubehör:',
  downloads: {
    entireCatalogue: 'Gesamtkatalog',
    rotaryCamSwitches: 'Schaltwerke',
    gearboxes: 'Getriebe',
    couplings: 'Kupplungen',
    protectiveHousing: 'Schutzgehaeuse'
  }
}
