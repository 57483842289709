export default {
  phone_description: 'Fon:',
  phone: '+49 (0) 2364 10547-0',
  mangagement: 'Geschäftsführerin: Dipl.-Ing. Dipl.-Wirt.-Ing. Dorothee Leonard-Pauls',
  taxID: 'Umsatzsteuer-Identifikationsnummer gem. § 27 a UStG: DE127133943',
  registerCourt: 'Registergericht: Amtsgericht Gelsenkirchen',
  registerNumber: 'Registernummer: HRB 6119',
  liabilityNote: 'Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.',
  privacy: 'Datenschutzerklärung',
  copyright: 'Copyright © 2023 KuPa. Alle Rechte vorbehalten.'
}
