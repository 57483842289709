export default {
  title: 'Contact',
  subtitle: 'Personal',
  placeholder: {
    name: 'Name',
    company: 'Company',
    street: 'Street',
    city: 'ZIP, City',
    phone: 'Phone',
    mail: 'E-Mail',
    subject: 'Subject',
    message: 'Request...'
  },
  sendButton: 'Send'
}
