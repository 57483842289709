export default {
  phone_description: 'Phone:',
  phone: '+49 (0) 2364 10547-0',
  management: 'CEO: Dipl.-Ing. Dipl.-Wirt.-Ing. Dorothee Leonard-Pauls',
  taxID: 'Sales tax identification number according to § 27 a UStG: DE127133943',
  registerCourt: 'Register court: Amtsgericht Gelsenkirchen',
  registerNumber: 'Register number: HRB 6119',
  liabilityNote: 'LEONARD Engineering GmbH (LE) has no influence on the design and contents of third party internet websites. LE therefore expressly distances itself from the material of all third party internet websites, even if the LE website links to these external sites.',
  privacy: 'Privacy Policy',
  copyright: 'Copyright © 2023 KuPa. All rights reserved.'
}
