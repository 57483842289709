export default {
  title: 'Kontakt',
  subtitle: 'Persönlicher',
  placeholder: {
    name: 'Name',
    company: 'Firma',
    street: 'Straße',
    city: 'PLZ, Ort',
    phone: 'Telefon',
    mail: 'E-Mail',
    subject: 'Betreff',
    message: 'Nachricht...'
  },
  sendButton: 'Absenden'
}
