<template>
  <button class="solid-button">
    <slot/>
  </button>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

// eslint-disable-next-line
@Component
export default class SolidButton extends Vue {

}
</script>

<style lang="scss" scoped>

@import '@/styles/colors.scss';

.solid-button {
  border: none;
  background-color: $solid-button-background-color;
  color: white;
  padding: 12px 48px;
  font-size: 17px;
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }
}

</style>
