export default {
  title: 'Ausbildung und Jobs',
  subtitle: 'Berufliche',
  introduction: 'Wir suchen motivierten Fachkräfte-Nachwuchs und bilden regelmäßig und gerne Feinwerkmechaniker, Schwerpunkt Maschinenbau (m/w/d) aus.',
  description: 'Die Arbeiten des Feinwerkmechanikers sind sehr interessant und abwechselungsreich. Neben guten Mathematik-Kenntnissen ist durch die steigende Zahl von CNC-Maschinen auch ein hohes analytisches Denkvermögen erforderlich. Wer sich für einen dieser zukunftssicheren Berufe in einer guten Ausbildungsstätte interssiert, kann sich bei uns bewerben!',
  currentJobs: 'Aktuell suchen wir Unterstützung (m/w/d) in den Bereichen',
  job1: 'CNC-Fräsen',
  job2: 'Baugruppenmontage',
  contact: 'und freuen uns über Ihre Kontaktaufnahme!'
}
